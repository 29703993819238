@mixin border-radius ($radius: 50px) {
  //-webkit-border-radius: $radius;
  //-moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin animation ($str) {
  //-webkit-animation: #{$str};
  //-moz-animation: #{$str};
  //-ms-animation: #{$str};
  //-o-animation: #{$str};
  animation: #{$str};
}

@mixin filter($value) {
  //-webkit-filter: $value;
  //-moz-filter: $value;
  //-o-filter: $value;
  filter: $value;
}

@mixin background-gradient ($str...) {
  //background: -webkit-linear-gradient($str);
  //background: -moz-linear-gradient($str);
  //background: -o-linear-gradient($str);
  background: linear-gradient($str);
}

@mixin white-space() {
  //white-space: -moz-pre-wrap;
  //white-space: -o-pre-wrap;
  white-space: pre-wrap;
}

@mixin box-shadow($str...) {
  //-webkit-box-shadow: $str;
  //-moz-box-shadow: $str;
  //-ms-box-shadow: $str;
  //-o-box-shadow: $str;
  box-shadow: $str;
}

@mixin text-shadow($str...) {
  //-webkit-text-shadow: $str;
  //-moz-text-shadow: $str;
  //-ms-text-shadow: $str;
  //-o-text-shadow: $str;
  text-shadow: $str;
}

@mixin mask () {
  mask-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
}

@mixin keyframes($name) {
  /*
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }
  */
  @keyframes #{$name} {
    @content;
  }
}
