.email {
  @extend %fa-icon;
  @extend .fas;
  &:before {
    content: fa-content($fa-var-envelope);
  }
}
.phone {
  @extend %fa-icon;
  @extend .fas;
  &:before {
    content: fa-content($fa-var-phone);
  }
}
.linkedin {
  @extend %fa-icon;
  @extend .fab;
  &:before {
    content: fa-content($fa-var-linkedin);
  }
}
.gitlab {
  @extend %fa-icon;
  @extend .fab;
  &:before {
    content: fa-content($fa-var-gitlab);
  }
}
.github {
  @extend %fa-icon;
  @extend .fab;
  &:before {
    content: fa-content($fa-var-github);
  }

}
.pubkey {
  @extend %fa-icon;
  @extend .fas;
  &:before {
    content: fa-content($fa-var-key);
  }

}