$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
$fa-font-size-base: 24px;
//$fa-font-path: "~@fortawesome/fontawesome-free/fonts";
@import url('https://fonts.googleapis.com/css?family=VT323&display=swap');
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "variables";
@import "mixin";
@import "icons";

body {
  background: #000;
  font-size: $font-size;
  font-weight: $font-weight;
  font-family: $font-family;
}
p {
  color: $text-color;
}
a {
  color: $text-color;
  font-size: 1.2em;
  margin-right: 10px;
  //text-decoration: underline;
  text-decoration: none;
  float: left;
}
hr {
  border: none;
  outline: none;
  position: relative;
  background: transparent;
  border-bottom: 2px dashed $text-color;
  width: 100%;
  margin: 0 auto;
  @include box-shadow(0 -1px 35px $text-shadow);
}
h2 {
  color: #edf2f3;
  text-align: center;
  padding-bottom: 20px;
  @include text-shadow(
    0 0 0 rgb(48, 48, 45),
    0 1px 0 rgb(21, 21, 18),
    0 2px 0 rgb(-6, -6, -9),
    0 3px 0 rgb(-33, -33, -36),
    0 6px 0 rgb(-114, -114, -117),
    0 7px 6px rgba(0, 0, 0, 0.85)
  );
  font-weight: 600;
  font-size: 2.9em;
  font-family: Arial, Helvetica, Geneva, sans-serif;
  text-transform: uppercase;
  letter-spacing: .3em;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    &:before {
      content: "\f00c"; /* FontAwesome Unicode */
      font-family: 'FontAwesome';
      display: inline-block;
      margin-left: -1.3em; /* same as padding-left set on li */
      width: 1.3em; /* same as padding-left set on li */
    }
    margin: 0;
    padding-left: 1.3em;
  }
}

.hard-right {
  float: right;
}

#content {
  &:after {
    content: "";
    width: 100%;
    height: 20%;
    position: absolute;
    bottom: -15px;
    left: 0;
    @include box-shadow(0 20px 10px -10px #000);
  }

  #terminal {
    &:before {
      content: "";
      width: 100%;
      height: 20%;
      position: absolute;
      top: 0;
      left: 0;
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(40,66,25,0.2)), color-stop(70%,rgba(32,57,17,0)), color-stop(99%,rgba(28,53,13,0)));
      //@include background-gradient(left, color-stop(0%, rgba(40, 66, 25, 0.2)), color-stop(70%, rgba(32, 57, 17, 0)), color-stop(99%, rgba(28, 53, 13, 0)));
      @include animation(scan 6.5s 1.0s infinite ease-in-out forwards);
    }
    &:after {
      //content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 1;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAAECAYAAABP2FU6AAAAE0lEQVQIHWP4//8/AxMDFMiDWQA2wAMhzy0v7QAAAABJRU5ErkJggg==); /*scanline*/
      background-repeat: repeat;
      //@include mask();
      @include filter(drop-shadow(15.32px 2.18px 7.35px rgba(20, 184, 189, 1)));
    }

    p {
      &:last-child {
        &:after {
          content: "\220E";
          font-size: 120%;
          @include animation(cursor .95s ease-in-out infinite);
        }
      }

      padding: 0.1em 0.2em;
      margin: 0;
      background-color: transparent;
      border-style: none;
      font-size: $font-size;
      font-weight: $font-weight;
      font-family: $font-family;
      @include box-shadow(none);
    }

    @include border-radius(50px);
    -webkit-filter: blur(0.3px);
    border-left: 20px solid #1b1b1b;
    border-right: 20px solid #1c1c1c;
    border-top: 25px solid #0f1310;
    border-bottom: 25px solid #0f1310;
    overflow: hidden;
    overflow-x: auto;
    //position: relative;
    color: $text-color;
    padding: 1.5em 1.5em 1.5em 5em;
    background-color: #162030;
    word-wrap: break-word;
    @include white-space;
    @include box-shadow(
      inset 0 0 0 9px rgba(21, 21, 20, 0.6),0 1px 60px 10px rgba(40, 46, 40, 9), inset 0 1px 80px 5px rgba(0, 0, 0, 0.97)
    );
    @include text-shadow(0 -1px 35px $text-shadow);
  }
  //position: relative;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAIAAAAiOjnJAAAD0ElEQVR4Ae3WsQ2AMBDFUCAZAFGw/6KREDvE3bsBXFiW7p/38x6Ogd0Grt1APAZ+A8LSQWJAWIlW0DnGYIGB7QbmWms7FJABr1ADiQFhJVpBhaWBxIDxnmgFNd41kBjwChOtoMLSQGJAWIlWUONdA4kB4z3RCuoVaiAxIKxEK6iwNJAYMN4TraDGuwYSA15hohVUWBpIDAgr0QpqvGsgMWC8J1pBvUINJAaElWgFFZYGEgPGe6IV1HjXQGLAK0y0ggpLA4kBYSVaQY13DSQGjPdEK6hXqIHEgLASraDC0kBiwHhPtIIa7xpIDHiFiVZQYWkgMSCsRCuo8a6BxIDxnmgF9Qo1kBgQVqIVVFgaSAwY74lWUONdA4kBrzDRCiosDSQGhJVoBTXeNZAYMN4TraBeoQYSA8JKtIIKSwOJAeM90QpqvGsgMeAVJlpBhaWBxICwEq2gxrsGEgPGe6IV1CvUQGJAWIlWUGFpIDFgvCdaQY13DSQGvMJEK6iwNJAYEFaiFdR410BiwHhPtIJ6hRpIDAgr0QoqLA0kBoz3RCuo8a6BxIBXmGgFFZYGEgPCSrSCGu8aSAwY74lWUK9QA4kBYSVaQYWlgcSA8Z5oBTXeNZAY8AoTraDC0kBiQFiJVlDjXQOJAeM90QrqFWogMSCsRCuosDSQGDDeE62gxrsGEgNeYaIVVFgaSAwIK9EKarxrIDFgvCdaQb1CDSQGhJVoBRWWBhIDxnuiFdR410BiwCtMtIIKSwOJAWElWkGNdw0kBoz3RCuoV6iBxICwEq2gwtJAYsB4T7SCGu8aSAx4hYlWUGFpIDEgrEQrqPGugcSA8Z5oBfUKNZAYEFaiFVRYGkgMGO+JVlDjXQOJAa8w0QoqLA0kBoSVaAU13jWQGDDeE62gXqEGEgPCSrSCCksDiQHjPdEKarxrIDHgFSZaQYWlgcSAsBKtoMa7BhIDxnuiFdQr1EBiQFiJVlBhaSAxYLwnWkGNdw0kBrzCRCuosDSQGBBWohXUeNdAYsB4T7SCeoUaSAwIK9EKKiwNJAaM90QrqPGugcSAV5hoBRWWBhIDwkq0ghrvGkgMGO+JVlCvUAOJAWElWkGFpYHEgPGeaAU13jWQGPAKE62gwtJAYkBYiVZQ410DiQHjPdEK6hVqIDEgrEQrqLA0kBgw3hOtoMa7BhIDXmGiFVRYGkgMCCvRCmq8ayAxYLwnWkG9Qg0kBoSVaAUVlgYSA8Z7ohXUeNdAYsArTLSCCksDiQFhJVpBjXcNJAaM90QrqFeogcSAsBKtoMLSQGLAeE+0gn6NXXdvK2/RcgAAAABJRU5ErkJggg==); /*Noise*/
  padding: 10px 25px 20px;
  text-align: left;
  @include border-radius(20px);
  @include box-shadow(
    0 0 0 rgb(194, 197, 198),
    0 1px 0 rgb(191, 194, 195),
    0 2px 0 rgb(189, 192, 193),
    0 3px 0 rgb(186, 189, 190),
    0 4px 0 rgb(183, 186, 187),
    0 5px 0 rgb(180, 183, 184),
    0 6px 0 rgb(178, 181, 182),
    0 7px 0 rgb(175, 178, 179),
    0 8px 0 rgb(172, 175, 176),
    0 9px 0 rgb(169, 172, 173),
    0 10px 0 rgb(167, 170, 171),
    0 11px 0 rgb(164, 167, 168),
    0 12px 0 rgb(161, 164, 165),
    0 13px 0 rgb(158, 161, 162),
    0 14px 0 rgb(156, 159, 160),
    0 15px 0 rgb(153, 156, 157),
    0 16px 0 rgb(150, 153, 154),
    0 17px 0 rgb(147, 150, 151),
    0 18px 17px rgba(0, 0, 0, 0.15),
    0 18px 1px rgba(0, 0, 0, 0.5),
    0 0 17px rgba(0, 0, 0, .2)
  );
}

@include keyframes(scan) {
  from {
    top: -50%;
    left: 0;
  }

  to {
    top: 150%;
    left: 0;
  }
}
@include keyframes(cursor) {
  from {
    opacity: 1.0;
  }
  to {
    opacity: 0.0;
  }
}